<template>
  <div>
    <video class="rounded"
           src="@/assets/news-network/videos/mutt.mp4"
           onclick="this.paused ? this.play() : this.pause();">
    </video>
    <p>
      First LEGO Universe fans picked a pet to be created for Brickkeeper, and now you've made a name for the critter!  With your recent poll votes, you've tagged Professor's robo-pup with the moniker of "MUTT," which stands for "Motorized Universal Technology Terrier." MUTT's rapidly wagging robo-tail means he loves his new name!
    </p>
    <p>
      Keep an eye out for more fun featuring Brickkeeper's tiny but technologically advanced terrier!
    </p>
  </div>
</template>
